<template>
  <div>
    <b-card
      v-if="mode === 'update'"
      title="ค้นหาสมาชิก"
      no-body
    >
      <b-card-body>
        <!-- <div class="d-flex justify-content-between  flex-wrap"> -->
        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="เบอร์โทร / ยูส"
                label-for="mc-first-name"
              >
                <b-form-input
                  id="mc-first-name"
                  v-model="userInput"
                  placeholder="เบอร์โทร / ยูส"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group />
            </b-col>
            <!-- submit and reset -->
            <b-col>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                @click="searchMember"
              >
                ค้นหา
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                @click="clearSearch"
              >
                เคลียร์
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card-body>
    </b-card>
    <b-overlay
      id="overlay-background"
      :show="false"
      variant="light"
      opacity="0.5"
      :blur="'true'"
      rounded="sm"
    >
      <b-card
        :title="`ข้อมูลแอดมิน`"
      >
        <b-row>
          <b-col md="2">
            <h5 class="text-capitalize mb-75">
              ให้ใช้งาน
            </h5>
            <b-form-checkbox-group
              id="checkbox-group-1"
              v-model="adminForm.is_active"
              name="flavour-1"
            >
              <b-form-checkbox
                :value="true"
                switch
              >
                {{ adminForm.is_active.length > 0 ? 'เปิด':'ปิด' }}
              </b-form-checkbox>
            </b-form-checkbox-group>
          </b-col>
          <b-col md="3">
            <h5 class="text-capitalize mb-75">
              ยูสเซอร์
            </h5>
            <b-form-input
              id="mc-user-name"
              v-model="adminForm.phoneno"
              placeholder="ยูสเซอร์"
            />
          </b-col>
          <b-col md="3">
            <h5 class="text-capitalize mb-75">
              ชื่อ
            </h5>
            <b-form-input
              id="mc-first-name"
              v-model="adminForm.name"
              placeholder="ชื่อ"
            />
          </b-col>
          <b-col md="4">
            <h5 class="text-capitalize mb-75">
              รหัสผ่าน
            </h5>
            <b-form-input
              id="mc-first-name"
              v-model="adminForm.password"
              placeholder="รหัสผ่าน"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col
            md="3"
            class="mt-10"
          >
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="primary"
              @click="saveProfile(adminInfo.id, adminForm, 'loadingProfile')"
            >
              บันทึก
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
    <b-card
      title="สิทธิ์"
    >
      <b-row
        v-for="permgroup in allpermissions"
        :key="permgroup.group"
      >
        <b-col md="12">
          <b-card
            :title="permgroup.groupName"
          >
            <b-form-checkbox-group
              id="checkbox-group-2"
              v-model="adminForm.permission"
              name="flavour-2"
            >
              <b-form-checkbox
                v-for="perm in Object.keys(permgroup.permissions)"
                :key="`${permgroup.permissionPrefix}${perm}`"
                :value="`${permgroup.permissionPrefix}${perm}`.replace(/[^\w_]/gi, '')"
              >
                {{ permgroup.permissions[perm] }}
              </b-form-checkbox>
            </b-form-checkbox-group>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="3"
          class="mt-10"
        >
          <b-button
            variant="primary"
            @click="saveProfile(adminInfo.id, adminForm, 'loadingProfile')"
          >
            บันทึก
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-card
      v-if="mode === 'update'"
      title="50 กิจกรรมล่าสุด"
    >
      <b-table
        v-if="mode === 'update'"
        ref="receivedPromotionsTable"
        striped
        hover
        responsive
        class="position-relative"
        :per-page="15"
        :current-page="localVariables.activities_page"
        :items="userActivities"
        :fields="activity_fields"
      />
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="localVariables.activities_page"
            :total-rows="userActivities.length"
            :per-page="15"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
// import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCard, BTable, BBadge, BFormGroup, BFormSelect, BPagination, BFormInput, BButton, BCardBody, BRow, BCol, BForm, BCardText, BOverlay, BFormCheckbox, BFormCheckboxGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
import vSelect from 'vue-select'

export default {
  components: {
    BCard,
    BTable,
    // BBadge,
    BFormGroup,
    // BFormSelect,
    BPagination,
    // BCardText,
    // BInputGroup,
    BFormInput,
    // BInputGroupAppend,
    BButton,
    BCardBody,
    BRow,
    BCol,
    BForm,
    BOverlay,
    BFormCheckbox,
    BFormCheckboxGroup,
    // Cleave,
    // vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loadingProfile: false,
      userInput: null,
      isBusy: false,
      gauth: false,
      gauthQR: null,
      localVariables: {},
      pageOptions: [50, 100, 200, 500, 1000],
      totalWalletRows: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      tableQuery: {
        page: 1,
        page_size: 50,
      },
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'created_at', label: 'วันที่' },
        { key: 'username', label: 'ยูส/เบอร์' },
        { key: 'note', label: 'รายละเอียด' },
        { key: 'amount', label: 'จำนวน' },
        { key: 'balance', label: 'คงเหลือ' },
        { key: 'ref2', label: 'อ้างอิง 2' },
        { key: 'ref3', label: 'อ้างอิง 3' },
        { key: 'platform', label: 'ระบบ' },
      ],
      activity_fields: [
        { key: 'date', label: 'วันที่' },
        { key: 'hour', label: 'เวลา (นาฬิกา)' },
        { key: 'stat_type', label: 'ประเภท' },
        { key: 'game', label: 'ค่ายเกมส์' },
        { key: 'ip', label: 'IP' },
      ],
      /* eslint-disable global-require */
      adminInfo: null,
      adminForm: {
        is_active: [true],
      },
      allpermissions: [],
      mode: 'update',
      userActivities: [],
      /* eslint-disable global-require */
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    if (this.$route.params.admin === 'add-new-staff') {
      this.mode = 'add'
      this.adminInfo = {
        is_active: [],
      }
      this.adminForm = {
        is_active: [],
      }
      this.userActivities = []
    } else if (this.$route.params.admin !== null && this.$route.params.admin !== undefined && this.$route.params.admin !== 'add-new-staff') {
      this.userInput = this.$route.params.admin
      this.fetchRecords()
      this.mode = 'update'
    }
    this.fetchPermission()
  },
  methods: {
    searchMember() {
      this.fetchRecords()
      // this.$refs.receivedPromotionsTable.refresh()
    },
    clearSearch() {
      this.userInput = null
      this.tableQuery = {
        page: 1,
        page_size: 100,
      }
      this.$refs.receivedPromotionsTable.refresh()
    },
    async fetchPermission() {
      const { data: { success: permSuccess, permissions } } = await this.$http.get('admin/all-permissions')
      this.allpermissions = permissions
    },
    async fetchRecords(ctx) {
      console.log('[fetchRecords]', ctx)
      this.userActivities = []
      this.tableQuery.phoneno = this.userInput

      this.gauth = false
      this.gauthQR = null
      const {
        data: {
          success, staff, gauth: googleauth, qrSecret: googleAuthQR,
        },
      } = await this.$http.get(`admin/${this.tableQuery.phoneno}`)
      this.adminInfo = { ...staff, is_active: staff.is_active ? [true] : [] }
      this.adminForm = { ...staff, is_active: staff.is_active ? [true] : [] }
      this.gauth = googleauth
      this.gauthQR = googleAuthQR
      // const { data: _wallettx } = await this.$http.get(`wallet-transactions?${fieldName}=${this.userInput}&platform=WALLET`)
      // this.walletItems = _wallettx.wallet_transactions.data
      // this.totalWalletRows = _wallettx.wallet_transactions.total

      // this.fetchActivity()
      //
      return this.walletItems
    },
    async saveProfile(id, changes, loadingRef) {
      this[loadingRef] = true
      if (this.mode === 'update') {
        const allowChanges = ['phoneno', 'name', 'permission', 'password', 'is_active']
        const changeMade = {}
        Object.keys(changes).forEach(keyItem => {
          if (allowChanges.indexOf(keyItem) >= 0 && changes[keyItem] !== this.adminInfo[keyItem])
            changeMade[keyItem] = changes[keyItem]
          if (keyItem === 'is_active')
            changeMade[keyItem] = changes[keyItem].length > 0
        })

        try {
          if (changeMade.phoneno)
            changeMade.phoneno = changeMade.phoneno.replace(/-/ig, '')
          const { data: saveProfileResult } = await this.$http.put(`admin/${id}`, changeMade)
          this[loadingRef] = false
          if (saveProfileResult.success) {
            this.adminInfo = { ...saveProfileResult.staff, is_active: saveProfileResult.staff.is_active ? [true] : [] }
            this.adminForm = { ...saveProfileResult.staff, is_active: saveProfileResult.staff.is_active ? [true] : [] }
            this.$bvToast.toast(`แอดมิน ${this.adminForm.phoneno} ${this.adminForm.name}`, {
              variant: 'success',
              title: 'แก้ไขแอดมินสำเร็จ',
              toaster: 'b-toaster-bottom-right',
              solid: true,
              appendToast: false,
            })
            return
          }
          throw new Error(saveProfileResult.error_message || '')
        } catch (err) {
          console.log(err)
          this.$swal({
            title: 'แก้ไขแอดมินผิดพลาด',
            text: err,
            icon: 'error',
            showCancelButton: false,
            confirmButtonText: 'OK',
            customClass: {
              confirmButton: 'btn btn-info',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          })
        }
      } else if (this.mode === 'add') {
        const changeMade = {}
        Object.keys(changes).forEach(keyItem => {
          if (keyItem === 'is_active')
            changeMade[keyItem] = changes[keyItem].length > 0
          else
            changeMade[keyItem] = changes[keyItem]
        })
        try {
          if (changeMade.phoneno)
            changeMade.phoneno = changeMade.phoneno.replace(/-/ig, '')
          const { data: saveProfileResult } = await this.$http.post('admin', changeMade)
          this[loadingRef] = false
          if (saveProfileResult.success) {
            this.adminInfo = { ...saveProfileResult.staff }
            this.adminForm = { ...saveProfileResult.staff }
            this.$bvToast.toast(`แอดมิน ${this.adminForm.phoneno} ${this.adminForm.name}`, {
              variant: 'success',
              title: 'เพิ่มแอดมินสำเร็จ',
              toaster: 'b-toaster-bottom-right',
              solid: true,
              appendToast: false,
            })
            this.$router.replace({ name: 'manage-admin-detail', params: { admin: saveProfileResult.staff.phoneno } })
            return
          }
          throw new Error(saveProfileResult.error_message || '')
        } catch (err) {
          console.log(err)
          this.$swal({
            title: 'เพิ่มแอดมินผิดพลาด',
            text: err,
            icon: 'error',
            showCancelButton: false,
            confirmButtonText: 'OK',
            customClass: {
              confirmButton: 'btn btn-info',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          })
        }
      }
      this[loadingRef] = false
    },
    async resetFirstOTP(id) {
      this.$swal({
        title: 'ยืนยันการออกรหัสแรกเข้าใหม่?',
        text: `เป็นการยกเลิก Google Authenticator เดิมของ ${this.adminForm.name}, ยืนยันที่จะดำเนินการหรือไม่`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'ใช่ ตกลง',
        customClass: {
          confirmButton: 'btn btn-info',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value)
          return this.$http.post('admin/reset-fotp', this.adminInfo)
        return { data: { success: false } }
      }).then(({ data }) => {
        if (!data.success && data.error_message) {
          this.$swal({
            title: 'ออกรหัสแรกเข้าใหม่',
            text: data.error_message,
            icon: 'error',
            showCancelButton: false,
            confirmButtonText: 'ok',
            customClass: {
              confirmButton: 'btn btn-info',
            },
            buttonsStyling: false,
          })
          return { data: { success: false } }
        }
        return { data: { success: true } }
      }).then(({ data }) => {
        if (data.success) {
          this.fetchRecords()
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.mt-10 {
  margin-top: 20px;
}
.text-right {
  text-align: right;
}
</style>
